:root {
  // --Red-Hat: "Red Hat Display", sans-serif;
  // --Charlie-Kingdom: "Charlie Kingdom", sans-serif;

  --CeraPro-Regular-Italic: "CeraPro Regular Italic", sans-serif;
  --CeraPro-Medium: "CeraPro Medium", sans-serif;
  --CeraPro-Light: "CeraPro Light", sans-serif;
  --CeraPro-Bold: "CeraPro Bold", sans-serif;
  --CeraPro-Black: "CeraPro Black", sans-serif;
  --CeraPro-Black-Italic: "CeraPro Black Italic", sans-serif;
  --Times-New-Roman-Italic: "Times New Roman Italic", sans-serif;
  --Times-New-Roman: "Times New Roman", sans-serif;
  --DM-Serif-Text: "DM Serif Text";

  --goldenColor: linear-gradient(
    90deg,
    #f7e483 0%,
    #d6b052 25%,
    #d29935 50%,
    #f6df7c 75%,
    #d2ab4d 100%
  );
  --dark-green: #3c6056;
  --ch-yellow: #ccae32;
  --ch-pink: #d28e9b;
  --ch-red: #811324;
  --ch-green: #41655b;
  --ch-skinColor: #d0c3ab;
  --ch-brown: #2c1716;
  --ch-biscuit: #c9a691;
  --ch-red-2: #801424;
}

.font-cera-regular-italic {
  font-family: var(--CeraPro-Regular-Italic) !important;
}
.font-cera-medium {
  font-family: var(--CeraPro-Medium) !important;
}
.font-cera-black-italic {
  font-family: var(--CeraPro-Black-Italic) !important;
}
.font-cera-light {
  font-family: var(--CeraPro-Light) !important;
}
.font-cera-bold {
  font-family: var(--CeraPro-Bold) !important;
}
.font-cera-black {
  font-family: var(--CeraPro-Black) !important;
}
.font-times-new-roman-italic {
  font-family: var(--Times-New-Roman-Italic) !important;
}
.font-times-new-roman {
  font-family: var(--Times-New-Roman) !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// chocochi styles

.button-active {
  background-color: var(--primary-colors) !important;
  color: #ffff !important;
}
.futureDeliveryDate {
  .react-date-picker {
    .react-date-picker__wrapper {
      width: 100%;
      min-width: 160px;
      padding: 8px;
      border-radius: 11px;
      border: 1px solid #ececec;
    }
    .react-date-picker__calendar {
      width: 254px;
      .react-calendar {
        border: 1px solid #ececec;
        border-radius: 12px;
        margin-top: 7px;
      }
      .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
        border-top-left-radius: 10px;
      }
      .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
        border-top-right-radius: 10px;
      }
      .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
      .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
        display: none;
      }
      .react-calendar__tile--active {
        background-color: var(--secondary-color);
        border-radius: 50%;
      }
      .react-calendar__tile:enabled:hover,
      .react-calendar__tile:enabled:focus {
        border-radius: 50%;
      }
    }
  }
  .react-date-picker__clear-button.react-date-picker__button {
    // &:nth-child(1) {
    display: none;
    // }
  }
}
.gridItem {
  padding: 0 25px !important;
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.widget-category-2 ul li:hover {
  border: none !important;
  box-shadow: none !important;
}
.productSingleContainer {
  .flotingCart {
    background: linear-gradient(0deg, #2c1716 0%, #2c1716 100%), #d9d9d9;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.12);
    .button-add-to-cart {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        background: linear-gradient(
          90deg,
          #f7e483 0%,
          #d6b052 25%,
          #d29935 50%,
          #f6df7c 75%,
          #d2ab4d 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-family: var(--CeraPro-Bold);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .button-add-to-cart.buy-now-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        137deg,
        #ccae32 0%,
        #f7e483 15.63%,
        #f6df7c 31.25%,
        #d6b052 55.73%,
        #d2ab4d 75%,
        #d29935 100%
      ) !important;
      background-size: cover;
      .but-now-text {
        color: var(--ch-red-2);
        text-align: center;
        font-family: var(--CeraPro-Bold);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 100% !important;
      }
    }
  }

  .title-detail {
    color: var(--dark-green);
    font-family: var(--CeraPro-Medium);
    font-size: 14.621px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .current-price {
    // font-family: var(--CeraPro-Black);
    font-family: var(--CeraPro-Medium);
    color: var(--ch-brown);
    text-align: center;
    font-size: 34.513px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .old-price {
    color: #c9a692 !important;
    text-align: center;
    font-family: var(--CeraPro-Medium);
    font-size: 22px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .save-price {
    font-family: var(--CeraPro-Light);
    font-size: 13.851px !important;
    color: #20c261 !important;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .discription_markup {
    color: #000;
    text-align: justify;
    font-family: var(--CeraPro-Light);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}
.location-popup {
  .mobile_login {
    .btn-close {
      right: 15px;
      top: 3%;
    }
    .save-location-btn,
    .deal-top {
      display: none !important;
    }
    .modal-body {
      padding: 0px !important;
    }
    .location-popup-body {
      padding: 0 !important;
    }

    .location_search_wrap {
      // margin-bottom: 55px;
      padding: 1rem;
      .search_map {
        position: unset !important;
        input {
          width: 100%;
        }
        .searched_list {
          width: 100%;
          max-height: 230px;
          overflow: hidden;
        }
      }
    }

    .gm-control-active,
    .gmnoprint {
      display: none !important;
    }
    .locate_map {
    }
  }
}
.header-web {
  display: block;
  @media screen and (max-width: "480px") {
    display: none;
  }
}
.sidebar-widget {
  .swiper-wrapper {
    justify-content: center;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }
}
.product-cart-wrap .product-content-wrap .product-price {
  .font-head {
    color: var(--ch-brown);
    text-align: center;
    // font-family: var(--CeraPro-Black);
    font-family: var(--CeraPro-Medium);
    font-size: 17px !important;
  }
  .old-price {
    color: #c9a692 !important;
    text-align: center;
    font-family: var(--CeraPro-Medium);
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
  }
  .price_percentage {
    font-size: 14px !important;
  }
}
.shop-main-qa {
  color: var(--ch-green);
  text-align: center;
  font-family: var(--CeraPro-Light);
  font-size: 25px;
  font-weight: 300;
  margin-top: 40px;
}
.home-review {
  .review-slide {
    width: 233px;
    background-color: #d28e9b;
    text-align: center;
    h4 {
      color: var(--ch-red-2);
      font-family: var(--CeraPro-Bold);
      font-size: 18.018px;
      font-weight: 700;
    }
    span {
      color: #ffb9c6;
      font-family: var(--CeraPro-Bold);
      font-size: 8.686px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2.953px;
      text-transform: uppercase;
    }
    p {
      color: #ffffff;
      font-family: var(--Times-New-Roman-Italic);
      font-size: 12.012px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
  }
  .section-title {
    .section_head_h3 {
      margin-bottom: 10px !important;
    }
    width: 83%;
    margin: auto;
  }
}
.signature-card-container {
  h3 {
    font-family: var(--CeraPro-Light);
    color: var(--ch-green);
    text-align: center;
    font-size: 25px;
    font-weight: 300;
  }
  p {
    color: var(--ch-red-2);
    font-family: var(--Times-New-Roman-Italic);
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .item-card {
    p {
      color: #c9a692 !important;
      font-family: var(--CeraPro-Bold);
      text-align: center;
      font-size: 14.034px;
      font-weight: 700;
      text-transform: uppercase;
      // width: 75%;
      margin: auto;
    }
  }
  .mobileBanner {
    img {
      border-radius: 40px;
      @media screen and (max-width: 480px) {
        border-radius: 0px;
      }
    }
  }
}
.about-card-container {
  position: relative;
  background-color: #d1c2ab;
  .about-card-div {
    width: 60%;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
    margin: auto;
  }
  img {
    margin-top: -5.5rem;
  }
  h3 {
    color: var(--ch-green);
    text-align: center;
    font-family: var(--CeraPro-Light);
    font-size: 25px;
    font-weight: 100;
    width: 75%;
    margin-bottom: 10px;
  }
  p {
    font-family: var(--CeraPro-Regular-Italic);
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    width: 100%;
  }
  a {
    color: var(--ch-green);
    text-align: center;
    font-family: var(--CeraPro-Light);
    font-size: 12.097px;
    font-weight: 300;
    text-decoration-line: underline;
    margin-top: 10px;
  }
}
.brand-color {
  width: 100%;
  height: 7px;
  display: flex;
  div {
    width: 14.285%;
    height: 100%;
  }
  div:nth-child(1) {
    background-color: var(--ch-yellow);
  }
  div:nth-child(2) {
    background-color: var(--ch-pink);
  }
  div:nth-child(3) {
    background-color: var(--ch-red);
  }
  div:nth-child(4) {
    background-color: var(--ch-green);
  }
  div:nth-child(5) {
    background-color: var(--ch-skinColor);
  }
  div:nth-child(6) {
    background-color: var(--ch-brown);
  }
  div:nth-child(7) {
    background-color: var(--ch-biscuit);
  }
}
.btn:not(.hover-none):hover,
.button:not(.hover-none):hover {
  background-color: var(--primary-colors) !important;
  color: #fff !important;
  span {
    color: #fff !important;
  }
  border: 1px solid var(--primary-colors) !important;
}

.product-cart-wrap .product-content-wrap h2 {
  color: var(--dark-green);
  text-align: center;
  font-family: var(--CeraPro-Bold);
  @media screen and (max-width: "480px") {
    height: 35px;
  }
}
.melting-button {
  background-color: transparent !important;
  border: none;
  position: relative;
  .button-text {
    position: absolute;
    top: 17px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--goldenColor);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--CeraPro-Bold);
    font-size: 18px;
  }
}
.product-cart-wrap {
  .product-card-bottom {
    .product-card-bottom-button {
      width: 100%;
      height: 31.871px;
      border-radius: 14.501px;
      border: 0.725px solid var(--dark-green);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding: 0 !important;
      span {
        color: var(--dark-green);
        font-family: var(--CeraPro-Black);
        font-size: 14px !important;
      }
      &.outline {
        border-radius: 25px;
        background: linear-gradient(331deg, #41655b 0%, #193c32 100%), #d9d9d9;
        span {
          font-family: var(--CeraPro-Bold);
          background: linear-gradient(
            90deg,
            #f7e483 0%,
            #d6b052 25%,
            #d29935 50%,
            #f6df7c 75%,
            #d2ab4d 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
.product-cart-wrap {
  .product-card-offer-label {
    border-radius: 50%;
    background: var(--goldenColor);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: absolute;
    top: 13px;
    left: 13px;
    z-index: 9;
    .price-per {
      line-height: 15px;
      text-align: center;
      color: var(--ch-brown) !important;
      font-weight: 900 !important;
      letter-spacing: -0.62px;
      font-family: var(--CeraPro-Black);
      font-size: 16px !important;
    }
  }
}

.home-search-wrapper {
  // right: 65px;
  // width: 350px;
  .search-style-2 {
    form {
      border: none;
      border-radius: 5px !important;
      // border-radius: 0 ;
      padding: 0 0 0 10px;
      background-color: #35554c;
      color: #fff;
      input {
        width: 280px;
        @media screen and (max-width: 1200px) {
          width: 175px;
        }
        @media screen and (max-width: 480px) {
          width: 100%;
        }
        font-family: var(--CeraPro-Light);
        background-color: #35554c;
        color: #fff;
        &::placeholder {
          color: rgba(255, 255, 255, 1);
        }
      }
      @media screen and (max-width: 768px) {
        border-radius: 20px !important;
      }
    }
  }
}
.header-area {
  background: linear-gradient(180deg, #41655b 0%, #395c52 100%);
}
.width-fixed {
  width: 340px;
  @media screen and (max-width: 1200px) {
    width: 230px !important;
  }
  // width: 33%;
}
#swiperCard {
  .boxImages {
    @media screen and (max-width: 480px) {
      width: auto;
    }
    width: 300px;
    .box-qty {
      border: 1px solid lightgray;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      svg {
        color: #000000;
      }
      cursor: pointer;
    }
  }
  .addBoxCard {
    width: 300px;
    background-color: #fff;

    .box-content {
      ol {
        li {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          div {
            margin-right: 4px;
            padding: 2px;
            border: 1px solid lightgray;
            border-radius: 15px;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
          }
        }
      }
      // ol{
      //   list-style: decimal !important;
      // }
    }
  }
}

// chocochi styles
.fontColorGoldenList {
  li {
    background: var(--goldenColor);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 300 !important;
  }
}
.fontColorGolden {
  background: var(--goldenColor);
  // background: linear-gradient(90deg, #F7E483 0%, #D6B052 25%, #D29935 50%, #F6DF7C 75%, #D2AB4D 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.FontCharlie {
  font-family: var(--CeraPro-Black);
}
.overflow-hidden {
  overflow: hidden !important;
}
.sticky {
  position: sticky !important;
  top: 0;
}
.comments-area p {
  font-size: 14px !important;
  margin-bottom: 0;
}
.comments-area .comment-list .single-comment {
  border: none;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0;
}
.thumb-star {
  background-color: var(--primary-colors);
  color: #fff;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  font-size: 12px;
}
.new_cat_slider .card-2 {
  @media screen and (max-width: 426px) {
    height: 110px;
    width: 100px;
    min-height: 110px;
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    figure {
      height: 70px;
    }
    img {
      border-radius: 50%;
    }
  }
}
.new_cat_slider .swiper-wrapper {
  @media screen and (max-width: 426px) {
    height: 130px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: -16px;
  right: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: -16px;
  left: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-button-next {
  top: 60%;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0);
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 50%;
  color: blue;
  font-weight: 700;
  outline: 0;
  transition: background-color 0.2s ease, color 0.2s ease;

  &::after {
    font-size: 16px;
  }
}

.myNewArrow .swiper-button-prev {
  &:after {
    position: relative;
    left: -1px;
  }
}

.myNewArrow .swiper-button-next {
  &:after {
    position: relative;
    left: 1px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.myNewArrow .swiper-button-prev.swiper-button-disabled,
.myNewArrow .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

/* flex */
.flex-col {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: end !important;
}
.justify-between {
  justify-content: space-between !important;
}

.flex-1 {
  flex: 1 !important;
}
.sm\:align-items-end {
  @media screen and (max-width: 480px) {
    align-items: flex-end !important;
  }
}

///////@at-root
.custom_dot_line {
  transform: rotate(90deg);
  height: 32px;
  width: 100px;
}
.dot_wrapper {
  height: 100px;
  width: 32px;
}
.dot_wrapper svg {
  height: 100px;
  width: 32px;
  margin-left: -12px;
  margin-top: 5px;
}
.otp_input {
  height: 100%;
  width: 100% !important;
  border: #adb5bd solid;
  border-radius: 5px;
}

.path-vert {
  stroke-dasharray: 10;
  /*   stroke-dashoffset: 800; */
  // animation: draw1 5s linear alternate;
}

@keyframes draw1 {
  from {
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dashoffset: 600;
  }
}
.otp_container {
  width: 55%;
}
.otp_container div {
  width: 40px;
  height: 40px;
}
.otp_container.otp_cont_2 div {
  width: 75px;
  height: 40px;
  margin: 0px 5px 0 0;
}

.otp_container input {
  padding: 0 !important;
}
.otp_focus {
  border: rgb(0, 0, 0) solid !important;
  // @media screen and (max-width:768px) {
  // border:none !important;
  // }
}
.custom_location {
  background-color: #35554c !important;
  border-radius: 10px;
}
.custom_location a {
  background-color: #35554c !important;
  height: 40px;
  padding: 10px !important;
}

.custom_location a img {
  margin-right: 10px !important;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.g_marker {
  position: absolute;
  width: 38px;
  height: 37px;
  background-image: url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: grab;
}
.g_autoComplete {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  text-align: center;
}
.g_googlemap {
  width: 100%;
  height: 100%;
}
.main-map-wrapper {
  height: 100vh;
  width: 100%;
  margin-bottom: 4rem;
  filter: drop-shadow(-1px 5px 3px #ccc);
}

.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}
.search_map {
  position: absolute;
  top: 10px;
  left: 10px;
  // transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}
.search_map input {
  padding: 0 15px;
  font-size: 1rem;
  width: 80%;
  background: #fff;
  height: 40px;
  border-radius: 5px;
}
.locate_map {
  position: absolute;
  top: 4rem;
  right: 9px;
  z-index: 10;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 5px;
}
.locate_map_mob {
  position: absolute;
  top: 8rem;
  right: 9px;
  z-index: 10;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 5px;
}
.searched_list {
  padding: 1rem;
  padding-top: 5px;
  background: white;
  width: 80%;
  border-radius: 0 0 10px 10px;
  margin-top: 1px;
  z-index: 999;
}

.display-sm-block {
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
  }
}
.display-sm-none {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.display-lg-none {
  display: none !important;
  @media screen and (max-width: 1200px) {
    display: block !important;
  }
}
.display-md-none {
  display: block;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.positioning {
  top: 11px;
  right: 10px;
}

.list_cat {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.custom-filter-bar {
  @media screen and (max-width: 1200px) {
    position: absolute;
    background: white;
    max-width: 30%;
    min-width: 300px;
    left: 0;
    top: 0;
    padding: 2rem;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    z-index: 99;
    display: block !important;
    max-height: 100vh;
    height: 100vh;
    overflow-y: scroll;
  }
}

.background-cover {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
    background-color: black;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}
.bg-lightGray {
  background-color: #f4f4f4;
}
.shoplist-child {
  // min-height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
}
.shoplist {
  width: 100%;
}

.price-per {
  font-size: 14px !important;
  color: #2e9d5e !important;
  font-weight: 500;
}
.form-check-input:checked {
  background-color: #1b7834 !important;
  border-color: #8aa598 !important;
}
.display-md-block {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
  }
}
.sm\:flex-col {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
  }
}
.md\:flex-col {
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
  }
}
.sm\:mt-1 {
  @media screen and (max-width: 480px) {
    margin-top: 1rem !important;
  }
}

.sm\:display-between {
  @media screen and (max-width: 480px) {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}
.sm\:m-0 {
  @media screen and (max-width: 480px) {
    margin: 0 !important;
  }
}
.sm\:p-0 {
  @media screen and (max-width: 480px) {
    padding: 0px !important;
  }
}
.sm\:p-1 {
  @media screen and (max-width: 480px) {
    padding: 10px !important;
  }
}
.sm\:px-0 {
  @media screen and (max-width: 480px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.sm\:pt-2 {
  @media screen and (max-width: 480px) {
    padding-top: 1.5rem !important;
  }
}
.sm\:p-2 {
  @media screen and (max-width: 480px) {
    padding: 1.5rem !important;
  }
}
.sm\:p-3 {
  @media screen and (max-width: 480px) {
    padding: 2rem !important;
  }
}
.sm\:d-flex {
  @media screen and (max-width: 480px) {
    display: flex !important;
  }
}
.sm\:flex-col {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
  }
}
.cart_total_label {
  @media screen and (max-width: 480px) {
    text-align: start !important;
  }
}
.sm\:w-fit {
  @media screen and (max-width: 480px) {
    width: fit-content !important;
  }
}
.install_app_container {
  @media screen and (max-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.col-xs-6 {
  @media screen and (max-width: 480px) {
    width: 50% !important;
  }
}
.mob_detail_extralink .detail-qty {
  padding: 5px 7px 5px 14px !important;
  max-width: 77px !important;
  @media screen and (max-width: 480px) {
    // padding: 5px !important;
    // max-width: 100% !important;
    padding: 5px 45px 5px 20px !important;
    margin-bottom: 5px !important;
  }
}
.custom_collection_sidebar .image {
  height: 40px !important;
  width: 40px !important;
}

.floting-whatsapp-icon {
  position: fixed;
  right: 10px;
  height: 50px;
  width: 50px;
  bottom: 85px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  svg {
    font-size: 35px;
    color: #25d366;
    animation: beat 0.35s infinite alternate 2s;
    transform-origin: center;
  }
  @keyframes beat {
    to {
      transform: scale(1.2);
    }
  }
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.bg-element {
  right: 0;
  top: 0;
  width: 700px;
  height: 700px;
  z-index: -1;
  @media screen and (max-width: 768px) {
    right: -30px;
    top: -30px;
    width: 400px;
    height: 500px;
  }
  @media screen and (max-width: 480px) {
    right: -140px;
    width: 400px;
    height: 500px;
  }
}
.header-wrapper {
  @media screen and (max-width: 480px) {
    justify-content: space-between !important;
  }
}
.page-nav-link {
  a {
    font-family: var(--CeraPro-Black);

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin: 0 30px;
    &.red {
      color: rgba(255, 0, 65, 1);
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}
.highlight {
  .content {
    width: 40%;
    h4 {
      font-family: var(--CeraPro-Bold);
      font-weight: 400;
      font-size: 55.5556px;
      line-height: 102%;
      letter-spacing: -0.01em;
      color: #7b0063;
      margin-bottom: 10px;
    }
    p {
      font-family: var(--CeraPro-Light);
      font-weight: 800;
      font-size: 17px;
      line-height: 22px;
      color: #5f5f5f;
    }
    .product-price {
      .font-head-new {
        font-family: var(--CeraPro-Bold);
        font-weight: 400;
        font-size: 38.3537px;
        @media screen and (max-width: 768px) {
          font-size: 29.9856px;
        }
        line-height: 46px;
        color: #007551;
      }
      .old-price-new {
        font-family: var(--CeraPro-Black);

        font-weight: 400;
        font-size: 20.8303px;
        @media screen and (max-width: 768px) {
          font-size: 16.2855px;
        }
        line-height: 23px;
        color: #252928;
      }
      .price-per-new {
        font-family: var(--CeraPro-Light);
        font-weight: 900;
        font-size: 17.2197px;
        line-height: 23px;
        @media screen and (max-width: 768px) {
          font-size: 13.4627px;
        }
        letter-spacing: -0.03em;
        color: #007551;
      }
    }
    .btn-custom {
      margin: 15px 0;
      background: #ff0041;
      border-radius: 7.14894px;
      width: 200px;
      height: 42px;
      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
  }
  .image-wrapper {
    width: 60%;
    height: 500px;
    img {
      object-fit: contain;
    }
    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    .content {
      width: 100% !important;
      padding: 30px 10px;
      h4 {
        font-size: 43.4343px;
      }
      p {
        font-size: 13.2909px;
      }
      .btn-custom {
        width: 140px;
        height: 32.84px;
      }
    }
    .image-wrapper {
      width: 100% !important;
      // margin-bottom: 4rem;
      // img {
      //   transform: translateX(20px);
      // }
    }
  }
}
.video_banner {
  margin-top: 4rem;
  h4 {
    font-family: var(--CeraPro-Bold);
    font-weight: 400;
    font-size: 38.5939px;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 25.4425px;
    }
  }
  .react-player {
    // margin: 6rem 0;
    position: relative;
    width: fit-content;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 3rem;
      margin-top: 3rem;
      width: 100%;
      height: 300px;
      .react-player-video {
        width: 100%;
        height: 100%;
        div {
          width: 100% !important;
        }
      }
    }
    .el {
      position: absolute;
      z-index: 0;
      &.el1 {
        right: -60px;
        bottom: -70px;
        @media screen and (max-width: 768px) {
          width: 81.9px;
          height: 81.9px;
          right: -30px;
          bottom: -30px;
          display: none;
        }
      }
      &.el2 {
        left: -70px;
        top: -40px;
        z-index: 2;
        @media screen and (max-width: 768px) {
          width: 71.77px;
          height: 71.77px;
          left: -30px;
          top: -20px;
          display: none;
        }
      }
      &.el3 {
        right: -65px;
        bottom: 90px;
        @media screen and (max-width: 768px) {
          width: 25.33px;
          height: 25.33px;
          right: -30px;
          bottom: 55px;
          display: none;
        }
      }
      &.el4 {
        left: -50px;
        top: 110px;
        @media screen and (max-width: 768px) {
          width: 16.89px;
          height: 16.89px;
          left: -25px;
          top: 55px;
          display: none;
        }
      }
    }
    .react-player-video {
      z-index: 1;
      iframe {
        border-radius: 44.6498px;
        @media screen and (max-width: 768px) {
          // border-radius: 21.5311px;
          border-radius: 0;
        }
      }
      .react-player__preview {
        border-radius: 44.6498px;
        @media screen and (max-width: 768px) {
          // border-radius: 21.5311px;
          border-radius: 0;
        }
      }
    }
    .ytp-chrome-top {
      display: none !important;
    }
  }
}
.shortContent {
  // margin-top: 2rem;
  // margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 0;
  .shortContentInner {
    @media screen and (max-width: 768px) {
      // flex-direction: column;
    }
  }
  .content {
    max-width: 335px;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      justify-content: unset;
      margin-bottom: 20px;
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 15px;
      @media screen and (max-width: 768px) {
        width: 90px;
        height: 90px;
      }
    }
    h4 {
      font-family: var(--CeraPro-Bold);
      margin-top: 5px;
      color: #ffffff;
      text-align: center;
      font-size: 14.034px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        width: 130px;
      }
    }
  }
}

.wideContent {
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    flex-direction: row-reverse;
  }
  &.right {
    flex-direction: row;
  }
  &.top {
    flex-direction: column-reverse;
    .content {
      width: 80%;
    }
    .contentImage {
      width: 100%;
      max-height: 500px;
      img {
        max-height: 500px;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 768px) {
      .contentImage {
        max-height: 500px;
        img {
          max-height: 500px;
        }
      }
    }
  }
  &.bottom {
    flex-direction: column !important;
    .content {
      width: 80%;
    }
    .contentImage {
      width: 100%;
      max-height: 400px;
      img {
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
  .content {
    width: 50%;
    margin: 2rem auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    &.withImg {
      width: 50%;
      padding: 0 80px;
      @media screen and (max-width: 1024px) {
        padding: 0;
        width: 80%;
      }
      @media screen and (max-width: 768px) {
        padding: 0;
        width: 90%;
      }
    }
    h4 {
      font-family: var(--CeraPro-Bold);
      font-weight: 400;
      font-size: 38.5939px;
      line-height: 116.5%;
      letter-spacing: -0.01em;
      // padding-bottom: 20px;
      margin-top: 0 !important;
    }
    p {
      font-family: var(--CeraPro-Light);
      font-weight: 800;
      font-size: 17px;
      line-height: 22px;
      // text-align: center;
      color: #5f5f5f;
    }
    button {
      height: 42px;
      background: #ff0041;
      border-radius: 7.14894px;
      margin-bottom: 10px;
      margin-top: 15px;
      width: fit-content;
    }
    @media screen and (max-width: 768px) {
      margin: 4rem auto;
      margin-top: 1rem;
      width: 80%;
      h4 {
        font-size: 25.44px;
      }
      p {
        font-size: 13.2px;
      }
      button {
        font-size: 11px;
      }
    }
  }

  .contentImage {
    width: auto;
    height: auto;
    max-height: 600px;
    img {
      width: 100%;
      height: 100%;
      max-height: 600px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse !important;
    .contentImage {
      width: 100%;
      max-height: 400px;
      img {
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
}

.image-grid {
  padding: 4rem 2rem;
  @media screen and (max-width: 768px) {
    padding: 0;
    .gridItem {
      height: auto !important;
      max-height: 100% !important;
    }
  }
  .gridItem {
    // padding: 10px;
    max-height: 570px;
    height: auto;
    // margin-bottom: 10px;
    img {
      // object-fit: cover;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}
.testimonials {
  margin: 0 5rem;
  @media screen and (max-width: 768px) {
    margin: 0;
    margin-top: 5rem;
  }
  h4 {
    font-family: var(--CeraPro-Bold);
    // font-weight: 400;
    // font-size: 38.5939px;
    // line-height: 116.5%;
    // letter-spacing: -0.01em;
    // color: #7b0063;
    // margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      // font-size: 26.6836px;
      // text-align: center;
      width: 80%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .accordion-header {
    // font-family: var(--CeraPro-Black);
    // font-weight: 400;
    font-size: 18.8867px;
    line-height: 21px;
    text-transform: capitalize;
    color: #ffffff;
    @media screen and (max-width: 768px) {
      font-size: 13.0582px;
    }
  }
  .accordion-item {
    background-color: #f4f4f4;
    margin-bottom: 10px;
  }
  .accordion-button {
    border: none;
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
  .accordion-collapse {
    padding: 10px;
  }
  .accordion-button:not(.collapsed) {
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
    color: #fff;
    background-color: #3a5d53;
    // .accordion-button:not(.collapsed)::after {
    //   background-image: url("../imgs/landing/icons/arrowUp.png") !important;
    // }
  }
  .accordion-body {
    // font-family: var(--CeraPro-Light);
    // font-family: var(--CeraPro-Bold);
    font-family: var(--CeraPro-Light);
    font-weight: 400;
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 13.0582px;
    }
    line-height: 25px;
    color: #000000;
  }
}
.customerReview {
  &.style-2 {
    .swiper-wrapper {
      justify-content: center;
      @media screen and (max-width: 768px) {
        justify-content: flex-start;
      }
    }
    .swiper-slide {
      height: 350px;
      // height: 280px;
    }

    .review-slide-style-2 {
      height: 350px;
      width: 100%;
      background-color: var(--ch-pink);
      .images-wrapper {
        .lazyload-wrapper {
          width: 100% !important;
          height: 100% !important;
        }
        width: 100%;
        height: 200px;
      }
      .slider-content {
        color: var(--ch-red-2);
        text-align: center;
        font-family: var(--CeraPro-Bold);
        font-size: 18.018px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      h4 {
        color: var(--ch-red-2);
        text-align: center;
        font-family: var(--CeraPro-Bold);
        font-size: 18.018px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p {
        color: #fff;
        text-align: center;
        font-family: var(--Times-New-Roman-Italic);
        font-size: 12.012px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        padding: 0 16px;
        padding-bottom: 15px;
      }
    }
  }

  .swiper-slide-active {
    .review-slide {
      background: rgba(123, 0, 99, 0.06);
      border-radius: 73px;
      transform: scale(1);
      @media screen and (max-width: 768px) {
        border-radius: 40px;
      }
    }
  }

  margin-top: 5rem;
  margin-bottom: 5rem;

  .review-slide {
    background: rgba(244, 244, 244, 0.65);
    border-radius: 35.0962px;
    padding: 50px;
    text-align: center;
    transform: scale(0.788);
    transition: all ease-in-out 0.3s;
    img {
      width: 70px;
      height: 70px;
    }
    p {
      font-family: var(--CeraPro-Light);
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }
    h4 {
      font-family: var(--CeraPro-Bold);
      font-weight: 400;
      font-size: 38.5939px;
      line-height: 116.5%;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: #7b0063;
      margin: 0 8rem;
      margin-bottom: 5rem;
      @media screen and (max-width: 768px) {
        font-size: 38.5939px;
        text-align: center;
        width: 90%;
        margin: auto;
        margin-bottom: 30px;
      }
    }

    // h4 {
    //     font-family: var(--CeraPro-Black);

    //   font-weight: 400;
    //   font-size: 22.4842px;
    //   line-height: 25px;
    //   text-transform: capitalize;
    //   color: #1e1e1e;
    // }
    @media screen and (max-width: 768px) {
      h4 {
        font-size: 13.5px;
      }
      p {
        font-size: 13px;
      }
      .review-slide {
        padding: 20px !important;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .slider-arrow {
    display: none;
  }
}
.footer {
  margin-top: 3rem;
  background-color: #e8e1d5;
  color: #000000;
  padding: 80px;
  img {
    width: 220px;
    // height: 156px;
    object-fit: contain;
  }
  p {
    font-family: var(--CeraPro-Light);
    // font-weight: 700;
    font-size: 17px;
    line-height: 161.3%;
    color: #000000;
  }
  .footer-link-widget {
    h3 {
      font-family: var(--CeraPro-Light);
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #000000;
    }
    ul {
      li {
        a {
          font-family: var(--CeraPro-Light);
          font-weight: 400;
          font-size: 14px;
          line-height: 153.3%;
          color: #000000;
        }
      }
    }
  }
  .mobile-social-icon {
    @media screen and (max-width: 768px) {
      justify-content: center !important;
    }
    a {
      background-color: transparent;
      // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 30px 20px;
    .logos {
      flex-direction: column;
      margin-bottom: 40px;
      // align-items: flex-start !important;
    }
    p {
      // width: 100%;
    }
    img {
      // width: 130px;
      // height: 92px;
      // &.logo-footer {
      //   margin-left: -15px;
      // }
    }
    .footer-link-widget {
      h3 {
        font-size: 24.8193px;
      }
    }
  }
}

.tada-animation {
  animation-name: tada;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: both;
  border-radius: 85px;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.related-product-wrap {
  .section-title {
    font-family: var(--CeraPro-Bold);
    font-weight: 400;
    font-size: 38.5939px;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin-bottom: 20px;
  }
}

.mobileCarousel {
  @media screen and (max-width: 768px) {
    .mobileContainer {
      margin: 0 !important;
      border-radius: 0 !important;

      .mobileBanner {
        border-radius: 0 !important;
        height: 100% !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

.section-wrapper {
  &.web {
    display: unset;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &.mobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: unset;
    }
  }
  &.common {
  }
}
.section-head {
  // margin: 0 8rem;
  margin-top: 3rem;
  // margin-bottom: 5rem;
  color: var(--ch-green);
  text-align: center;
  font-family: var(--CeraPro-Bold);
  font-size: 25px !important;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 38.5939px;
    text-align: center;
    width: 90%;
    margin: auto;
    margin-top: 2rem;

    // margin-bottom: 30px;
  }
}
.section-description {
  color: var(--ch-red-2);
  text-align: center;
  font-family: var(--DM-Serif-Text);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-varient {
  &.related-product-wrap {
    .pages-product-varient {
      .swiper-wrapper {
        justify-content: center;
        @media screen and (max-width: 768px) {
          justify-content: flex-start;
        }
      }
    }

    .shoplist-child {
      @media screen and (max-width: 768px) {
        width: 200px;
      }
      @media screen and (max-width: 480px) {
        width: 180px;
      }
      @media screen and (max-width: 380px) {
        width: 170px;
      }
      @media screen and (max-width: 330px) {
        width: 150px;
      }
    }
  }
}

.custom-cart-button-group {
  border: 1px solid #dadee2;
  min-height: 150px;
  height: auto;
  border-radius: 20px;
  background-color: #f4f6fa;
  // padding: 1rem;
  margin-bottom: 2rem;
  .chat-w-exp {
    border-radius: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
  }
  .buttons {
    border-bottom: 1px solid #dadee2;
  }
  .content {
    display: flex;
    justify-content: space-between;
    .border-r-1 {
      border-right: 1px solid #dadee2;
    }
  }
}

.footerAddress {
  margin-left: 3rem;
  @media screen and (max-width: 768px) {
    text-align: center;
    margin: 0 !important;
    .mobile-links {
      border-right: 1px solid #0009;
      line-height: 12px;
      padding-left: 10px;
      padding-right: 10px;
      &:last-child {
        border: none;
      }
    }
  }
}

.delete-modal {
  .modal-content {
    background-clip: unset;
  }
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}

.modal-scroll-div {
  max-height: 630px;
  overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   width: 5px;
  // }
  // &::-webkit-scrollbar-track {
  //   border-radius: 8px;
  //   background-color: #95a5a6;
  //   border: 1px solid #cacaca;
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 8px;
  //   background-color: var(--primary-colors);
  // }
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 80px);
    height: calc(90vh - 80px);
  }
}

.cod-confirm-modal-image {
  height: 50px;
  width: 50px;
}
.popup-confirm-button {
  min-width: 300px;
  @media screen and (max-width: 768px) {
    min-width: 65%;
  }
}

.cat-slider {
  color: #832229;
  font-weight: 500;
  font-family: sans-serif;
}

.cat-name:hover {
  background-color: #cfc2a9;
}
